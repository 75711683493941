import camelcaseKeys from 'camelcase-keys';
import Store from '../libs/store';

import config from '../config';
import * as ResponseInterface from '../interfaces/achieve-bot-api-response';

const urlPrefix = config.apiUrl;

class ApiError extends Error {
  code: string;

  message: string;

  statusCode: number;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor({ code, message, statusCode }: { code: string; message: string; statusCode: number }, ...params: any) {
    super(...params);

    this.code = code;
    this.message = message;
    this.statusCode = statusCode;
  }
}

const queryParams = (params: object): string => {
  const result = Object
    .entries(params)
    .filter(([, value]) => !(Array.isArray(value) && value.length === 0))
    .filter(([, value]) => value !== undefined)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return value
          .map((item) => `${encodeURIComponent(key)}[]=${encodeURIComponent(item)}`)
          .join('&');
      }

      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .join('&');

  return result
    ? `?${result}`
    : '';
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const req = async (method: string, uri: string, params = {}): Promise<any> => {
  const url = `${urlPrefix}${uri}`;
  const reqUrl = (method === 'GET')
    ? url + queryParams(params)
    : url;

  const body = (method === 'GET')
    ? null
    : JSON.stringify(params);

  const accessToken = Store.get(Store.resources.accessToken);

  const fetchOptions = {
    method,
    body,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const res = await fetch(reqUrl, fetchOptions);
  const resJson = await res.json();
  if (res.ok) {
    return camelcaseKeys(resJson, { deep: true });
  }

  if (resJson.error_code) {
    if (resJson.error_code === 'INVALID_TOKEN' || resJson.error_code === 'UNAUTHORIZED') {
      Store.remove(Store.resources.accessToken);
      window.location.replace('/');
      return {};
    }
  }

  const errorObj: ApiError = {
    name: 'ApiError',
    code: resJson.error_code,
    message: resJson.message,
    statusCode: res.status,
  };
  throw new ApiError(errorObj);
};

const GET = 'GET';
const POST = 'POST';
// const PATCH = 'PATCH';
// const PUT = 'PUT';
// const DELETE = 'DELETE';

export default {
  ApiError,

  /* eslint-disable max-len */
  postToken: async (params: object): Promise<ResponseInterface.PostTokenResponseInterface> => req(POST, 'user/token', params) as Promise<ResponseInterface.PostTokenResponseInterface>,
  getTestProtected: async (): Promise<object> => req(GET, 'test-protected') as Promise<object>,
  getUserProfessions: async (): Promise<ResponseInterface.UserProfessionsInterface[]> => req(GET, 'professions') as Promise<ResponseInterface.UserProfessionsInterface[]>,
  getLocations: async (): Promise<ResponseInterface.LocationsInterface[]> => req(GET, 'locations') as Promise<ResponseInterface.LocationsInterface[]>,
  getInstitutions: async (): Promise<ResponseInterface.InstitutionsInterface[]> => req(GET, 'institutions') as Promise<ResponseInterface.InstitutionsInterface[]>,
  getPrograms: async (): Promise<ResponseInterface.ProgramsInterface[]> => req(GET, 'programs') as Promise<ResponseInterface.ProgramsInterface[]>,
  getDisciplineGroups: async (): Promise<ResponseInterface.DisciplineGroupsInterface[]> => req(GET, 'discipline-groups') as Promise<ResponseInterface.DisciplineGroupsInterface[]>,
  /* eslint-enable max-len */
};
