import React, { useEffect, Suspense, lazy } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import 'normalize.css';

import Auth from '../../libs/auth';
import PrivateRoute from '../../components/PrivateRoute';

const SignInPage = lazy(() => import('../SignInPage'));
const CareerPage = lazy(() => import('../CareerPage'));
const PractitionerInterviewPage = lazy(() => import('../PractitionerInterviewPage'));
const FurtherStudyPage = lazy(() => import('../FurtherStudyPage'));

const App = ({ className }: { className: string }): React.ReactElement => {
  useEffect(() => {
    // TODO: remove this
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).signOut = (): void => {
      Auth.signOut();
      window.location.reload();
    };
  });

  return (
    <div className={className}>
      <div className="app">
        <Helmet>
          <title>AcheiveBot</title>
          <meta name="description" content="AcheiveBot" />
        </Helmet>
        <Suspense fallback="loading">
          <Router>
            <Switch>
              <Route exact path="/" component={SignInPage} />
              <PrivateRoute exact path="/career" component={CareerPage} />
              <PrivateRoute exact path="/practitioner-interview" component={PractitionerInterviewPage} />
              <PrivateRoute exact path="/further-study" component={FurtherStudyPage} />
            </Switch>
          </Router>
        </Suspense>
      </div>
    </div>
  );
};

export default styled(App)`
background: #000;
min-height: 100vh;

.app {
  max-width: calc(768px + 16px * 2);
  margin: 0 auto;
  display: flex;
  min-height: 100%;
  padding: 0 16px;
  flex-direction: column;
}

@media only screen and (max-width: 768px) {
  .app {
    padding: 0 5px;
  }
}
`;
