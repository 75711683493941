import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import Store from '../libs/store';

interface PrivateRouteProps extends RouteProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: any;
}

const PrivateRoute = (props: PrivateRouteProps): React.ReactElement => {
  const { component: Component, ...rest } = props;
  const isSignedIn = !!Store.get(Store.resources.accessToken);

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <Route
      {...rest}
      render={(routeProps): React.ReactElement => (
        isSignedIn ? (
          <Component {...routeProps} />
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: routeProps.location },
            }}
          />
        )
      )}
    />
  );
  /* eslint-enable react/jsx-props-no-spreading */
};

export default PrivateRoute;
