import Store from './store';
import AchieveBotApi from '../services/achieve-bot-api';

export default {
  signIn: async (firebaseIdToken: string): Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/camelcase
    const response = await AchieveBotApi.postToken({ id_token: firebaseIdToken });
    const { accessToken } = response;

    Store.set(Store.resources.accessToken, accessToken);
  },
  signOut: (): void => {
    Store.remove(Store.resources.accessToken);
  },
  isSignedIn: (): boolean => !!Store.get(Store.resources.accessToken),
};
