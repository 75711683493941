import store from 'store';

const resources = {
  accessToken: 'ACCESS_TOKEN',
};


export default {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set: (key: string, value: any): void => {
    store.set(key, value);
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get: (key: string): any => store.get(key),
  remove: (key: string): void => {
    store.remove(key);
  },
  resources,
};
