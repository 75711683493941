// eslint-disable-next-line @typescript-eslint/no-explicit-any
const configs: Record<string, any> = {
  development: {
    env: 'development',
    apiUrl: 'http://api.portal.fdmt.hk.local/achieve-bot/',
  },
  production: {
    env: 'production',
    apiUrl: 'https://api.portal.fdmt.hk/achieve-bot/',
  },
};

const sharedConfig = {
  env: process.env.NODE_ENV,


  firebase: {
    apiKey: 'AIzaSyDEPx9d2OJRt5o0T-ukL_HVrLcafSdeBCY',
    authDomain: 'auth.portal.fdmt.hk',
    databaseURL: 'https://portal-229702.firebaseio.com',
    projectId: 'portal-229702',
    storageBucket: 'portal-229702.appspot.com',
    messagingSenderId: '674729106254',
    appId: '1:674729106254:web:b28799a6d6737cc5ad1c1f',
  },
};

const env = process.env.NODE_ENV as string;

export default {
  ...configs[env],
  ...sharedConfig,
};
